import { z } from 'zod'

export default () => {
  const agent = navigator.userAgent
  return isUnder1651iOS(agent)
}

export const isUnder1651iOS = (userAgent: string) => {
  console.debug(`UserAgent -> ${userAgent}`)

  const matches = userAgent.match(/OS ((\d+_?){2,3})\s/)
  if ((userAgent.indexOf('iPhone') > -1 || userAgent.indexOf('iPad') > -1) && matches !== null) {
    const str = matches[1]
    const ver = str.replace(/_/g, '.')
    console.debug(`iOS version -> ${ver}`)
    return compareVersion(str.replace(/_/g, '.'), '16.5.1') <= 0
  }
  return false
}

export const Version = z.string().regex(/^\d+(\.\d+){0,4}$/)
export type Version = z.infer<typeof Version>

export const compareVersion = (verA: Version, verB: Version): number => {
  console.debug(`compareVersion -> ${verA} vs ${verB}`)
  // check format
  Version.parse(verA)
  Version.parse(verB)

  // same?
  if (verA === verB) {
    return 0
  }

  const unitsA = verA.split('.').map((unit) => parseInt(unit))
  const unitsB = verB.split('.').map((unit) => parseInt(unit))

  const len = Math.min(unitsA.length, unitsB.length)

  for (var i = 0; i < len; i++) {
    if (unitsA[i] > unitsB[i]) return 1
    if (unitsA[i] < unitsB[i]) return -1
  }

  // The one with the higher number of arrays is judged to be larger.
  if (unitsA.length > unitsB.length) return 1
  if (unitsA.length < unitsB.length) return -1

  // same
  return 0
}
